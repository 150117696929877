import styles from "./Mobilindir.module.css";

const Mobilindir = () => {
  return (
    <div className={styles.mobilindir}>
      <div className={styles.frameParent}>
        <div className={styles.frameWrapper}>
          <div className={styles.groupWrapper}>
            <div className={styles.frameContainer}>
              <div className={styles.myfinansLogo31Parent}>
                <img
                  className={styles.myfinansLogo31Icon}
                  alt=""
                  src="/myfinanslogo3-12@2x.png"
                />
                <div className={styles.textWrapper}>
                  <div className={styles.text}>
                    <span>my</span>
                    <span className={styles.finans}>Finans</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.textParent}>
          <b className={styles.text1}>Yeni Nesil Analiz Platformu</b>
          <div className={styles.textContainer}>
            <div className={styles.text2}>
              Telefonlardan girişlerde mobil uygulamamızı kullanmanızı öneririz,
              WEB Panelimiz için lütfen bilgisayardan giriş yapın.
            </div>
          </div>
          <div className={styles.buttonParent}>
            <div className={styles.button} onClick={() => window.open('https://apps.apple.com/tr/app/myfinans/id6670229290', '_blank')}>
              <img
                className={styles.vuesaxboldappleIcon}
                alt=""
                src="/vuesaxboldapple1.svg"
              />
              <b className={styles.button1}>Hemen İndir</b>
            </div>
            <div className={styles.button} onClick={() => window.open('https://play.google.com/store/apps/details?id=com.investcode.myFinans', '_blank')}>
              <div className={styles.googlePlayStore1}>
                <img className={styles.groupIcon} alt="" src="/ggg.png" />
              </div>
              <b className={styles.button1}>Hemen İndir</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mobilindir;
