import React, { useState, useEffect, useRef, useCallback } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable';
import SelectComponent from './Select';
import TopMenu from './TopMenu';
import LeftSideMenu from './LeftSideMenu';
import './AnaSayfa.css';
import Derinlikwindow from './DerinlikWindow';
import { initializeApp } from "firebase/app";
import { getDocs, getFirestore, limit } from "firebase/firestore";
import { collection, addDoc , where, query , orderBy,onSnapshot,setDoc,doc,increment,serverTimestamp} from "firebase/firestore"; 
import TakasWindow from './TakasWindow'; // TakasWindow'u import edin
import TemettuWindow from './TemettuWindow'; // TemettuWindow'u import edin
import FondagilimiWindow from './FondagilimiWindow'; // Bu satırı dosyanın başına ekleyin
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
  apiKey: "AIzaSyBPa2RvufioWFIN1TajQ1ySd1pUiZKZbbE",
  authDomain: "hisseanaliz-803ed.firebaseapp.com",
  projectId: "hisseanaliz-803ed",
  storageBucket: "hisseanaliz-803ed.appspot.com",
  messagingSenderId: "485376323277",
  appId: "1:485376323277:web:f0f4c904864a26e80a4384",
  measurementId: "G-8YCWPLWTF7"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const DraggableWindow = React.memo(({ id, title, onClose, position, onDragStop, isDerinlikWindow, isTakasWindow, isTemettuWindow, isFondagilimiWindow, children, onResize, initialSize }) => {
  const [size, setSize] = useState(initialSize || { width: 300, height: 400 });

  const handleResize = (e, direction, ref, delta) => {
    setSize({ width: ref.style.width, height: ref.style.height });
    onResize(id, { width: ref.style.width, height: ref.style.height });
  };

  return (
    <Draggable
      defaultPosition={position}
      onStop={(e, data) => onDragStop(id, { x: data.x, y: data.y })}
      handle=".handle"
      bounds="parent"
    >
      <Resizable
        size={size}
        onResizeStop={handleResize}
        minWidth={isDerinlikWindow ? 320 : (isTakasWindow ? 500 : (isTemettuWindow ? 400 : (isFondagilimiWindow ? 400 : 200)))}
        minHeight={isDerinlikWindow ? 890 : (isTakasWindow ? 550 : (isTemettuWindow ? 600 : (isFondagilimiWindow ? 500 : 400)))}
        maxWidth="100%"
        maxHeight="100%"
        style={isDerinlikWindow ? styles.derinlikWindow : (isTakasWindow ? styles.takasWindow : (isTemettuWindow ? styles.temettuWindow : (isFondagilimiWindow ? styles.fondagilimiWindow : styles.window)))}
      >
        <div className="handle" style={styles.handle}>
          <div style={styles.header}>
            {title}
            <button onClick={() => onClose(id)} style={styles.closeButton}>X</button>
          </div>
          <div style={isTemettuWindow ? styles.temettuContent : styles.content}>
            {children}
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
});

const App = () => {
  const [windows, setWindows] = useState([]);
  const [nextId, setNextId] = useState(1);
  const [activemenu, setactivemenu] = useState(false);
  const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);
  const [containerHeight, setContainerHeight] = useState('100vh');
  const containerRef = useRef(null);
  const [derinlikWindows, setDerinlikWindows] = useState([]);
  const [takasWindows, setTakasWindows] = useState([]);
  const [temettuWindows, setTemettuWindows] = useState([]);
  const [fondagilimiWindows, setFondagilimiWindows] = useState([]); // Bu satırı diğer state tanımlamalarının yanına ekleyin
  const [abone, setAbone] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const [activeWindow, setActiveWindow] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const features = [
    { 
      title: 'Derinlik', 
      content: '', 
      isDerinlikWindow: true,
      icon: 'chart-line' // Font Awesome veya başka bir ikon kütüphanesi kullanın
    },
    { title: 'Takas Analizi', content: '', isTakasWindow: true },
    { title: 'Temettü', content: '', isTemettuWindow: true },
    { title: 'Fon Dağılımı', content: '', isFondagilimiWindow: true }, // Yeni özellik eklendi
    { 
      title: 'Teknik Analiz', 
      content: '', 
      isTeknikWindow: true,
      icon: 'chart-candlestick'
    },
    { 
      title: 'Portföy Takibi', 
      content: '', 
      isPortfolioWindow: true,
      icon: 'wallet'
    },
    { 
      title: 'Haberler', 
      content: '', 
      isNewsWindow: true,
      icon: 'newspaper'
    }
  ];

  useEffect(() => {
    const savedWindows = JSON.parse(localStorage.getItem('windows') || '[]');
    setWindows(savedWindows);
    setNextId(savedWindows.length > 0 ? Math.max(...savedWindows.map(w => w.id)) + 1 : 1);

    // Derinlik pencerelerini yükle
    const savedDerinlikWindows = JSON.parse(localStorage.getItem('derinlikWindows') || '[]');
    setDerinlikWindows(savedDerinlikWindows);

    // Takas pencerelerini yükle
    const savedTakasWindows = JSON.parse(localStorage.getItem('takasWindows') || '[]');
    setTakasWindows(savedTakasWindows);

    // Temettu pencerelerini yükle
    const savedTemettuWindows = JSON.parse(localStorage.getItem('temettuWindows') || '[]');
    setTemettuWindows(savedTemettuWindows);

    // Fon Dağılımı pencerelerini yükle
    const savedFondagilimiWindows = JSON.parse(localStorage.getItem('fondagilimiWindows') || '[]');
    setFondagilimiWindows(savedFondagilimiWindows);
  }, []);

  useEffect(() => {
    localStorage.setItem('windows', JSON.stringify(windows));
    localStorage.setItem('derinlikWindows', JSON.stringify(derinlikWindows));
    localStorage.setItem('takasWindows', JSON.stringify(takasWindows));
    localStorage.setItem('temettuWindows', JSON.stringify(temettuWindows));
    localStorage.setItem('fondagilimiWindows', JSON.stringify(fondagilimiWindows));
  }, [windows, derinlikWindows, takasWindows, temettuWindows, fondagilimiWindows]);

  useEffect(() => {
    // Kullanıcı giriş yapmış mı kontrol et
    const storedUser = JSON.parse(localStorage.getItem('user'));
    
    if (storedUser && storedUser.uid) {
      const q = query(
        collection(db, "users"),
        where("userid", "==", storedUser.uid)
      );
      
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((docSnapshot) => {
          const userData = docSnapshot.data();
          
          if (userData.üyelik === "5" || userData.üyelik === "31") {
            localStorage.setItem("abkod", userData.üyelik);
          } else {
            navigate('/paketler')
            
           
            
           
           
          }
        });
      });
      
      return () => unsubscribe();
    }
  }, []);

  const addWindow = (feature) => {
    if (feature.isDerinlikWindow) {
      const newDerinlikWindow = {
        id: nextId,
        position: { x: 10 + (nextId * 20), y: 10 + (nextId * 20) },
        size: { width: 340, height: 700 },
        hisse: 'GARAN' // Varsayılan hisse
      };
      setDerinlikWindows([...derinlikWindows, newDerinlikWindow]);
    } else if (feature.isTakasWindow) {
      const newTakasWindow = {
        id: nextId,
        position: { x: 10 + (nextId * 20), y: 10 + (nextId * 20) },
        size: { width: 500, height: 550 },
        hisse: 'GARAN' // Varsayılan hisse
      };
      setTakasWindows([...takasWindows, newTakasWindow]);
    } else if (feature.isTemettuWindow) {
      const newTemettuWindow = {
        id: nextId,
        position: { x: 10 + (nextId * 20), y: 10 + (nextId * 20) },
        size: { width: 400, height: 500 }, // Yüksekliği 450'den 500'e çıkardık
        hisse: 'GARAN' // Varsayılan hisse
      };
      setTemettuWindows([...temettuWindows, newTemettuWindow]);
    } else if (feature.isFondagilimiWindow) {
      const newFondagilimiWindow = {
        id: nextId,
        position: { x: 10 + (nextId * 20), y: 10 + (nextId * 20) },
        size: { width: 400, height: 500 },
        hisse: 'GARAN' // Varsayılan hisse
      };
      setFondagilimiWindows([...fondagilimiWindows, newFondagilimiWindow]);
    } else {
      const newWindow = {
        id: nextId,
        ...feature,
        position: { x: 10 + (nextId * 20), y: 10 + (nextId * 20) },
        size: { width: feature.isDerinlikWindow ? 340 : (feature.isTakasWindow ? 500 : 300), 
                height: feature.isDerinlikWindow ? 700 : (feature.isTakasWindow ? 550 : 200) }
      };
      setWindows([...windows, newWindow]);
    }
    setNextId(nextId + 1);
  };

  const closeWindow = (id, type) => {
    if (type === 'derinlik') {
      setDerinlikWindows(derinlikWindows.filter(window => window.id !== id));
    } else if (type === 'takas') {
      setTakasWindows(takasWindows.filter(window => window.id !== id));
    } else if (type === 'temettu') {
      setTemettuWindows(temettuWindows.filter(window => window.id !== id));
    } else if (type === 'fondagilimi') {
      setFondagilimiWindows(fondagilimiWindows.filter(window => window.id !== id));
    } else {
      setWindows(windows.filter(window => window.id !== id));
    }
  };

  const updateContainerHeight = useCallback(() => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const windowBottoms = windows.map(w => w.position.y + (w.size?.height || 200));
      const maxWindowBottom = Math.max(...windowBottoms, containerRect.height);
      const newHeight = Math.max(window.innerHeight, maxWindowBottom + 100);
      setContainerHeight(`${newHeight}px`);
    }
  }, [windows]);

  useEffect(() => {
    updateContainerHeight();
    window.addEventListener('resize', updateContainerHeight);
    return () => window.removeEventListener('resize', updateContainerHeight);
  }, [updateContainerHeight]);

  const updateWindowPosition = (id, position, type) => {
    if (type === 'derinlik') {
      setDerinlikWindows(derinlikWindows.map(window => window.id === id ? { ...window, position } : window));
    } else if (type === 'takas') {
      setTakasWindows(takasWindows.map(window => window.id === id ? { ...window, position } : window));
    } else if (type === 'temettu') {
      setTemettuWindows(temettuWindows.map(window => window.id === id ? { ...window, position } : window));
    } else if (type === 'fondagilimi') {
      setFondagilimiWindows(fondagilimiWindows.map(window => window.id === id ? { ...window, position } : window));
    } else {
      setWindows(windows.map(window => window.id === id ? { ...window, position } : window));
    }
    updateContainerHeight();
  };

  const updateWindowSize = (id, size, type) => {
    if (type === 'derinlik') {
      setDerinlikWindows(derinlikWindows.map(window => window.id === id ? { ...window, size } : window));
    } else if (type === 'takas') {
      setTakasWindows(takasWindows.map(window => window.id === id ? { ...window, size } : window));
    } else if (type === 'temettu') {
      setTemettuWindows(temettuWindows.map(window => window.id === id ? { ...window, size } : window));
    } else if (type === 'fondagilimi') {
      setFondagilimiWindows(fondagilimiWindows.map(window => window.id === id ? { ...window, size } : window));
    } else {
      setWindows(windows.map(window => window.id === id ? { ...window, size } : window));
    }
    updateContainerHeight();
  };

  const updateHisse = useCallback((id, hisse, type) => {
    switch(type) {
      case 'derinlik':
        setDerinlikWindows(prev => prev.map(window => 
          window.id === id ? { ...window, hisse } : window
        ));
        break;
      // ... diğer durumlar
    }
  }, []);

  const handleSearchClick = () => {
    setIsSearchPopupOpen(true);
  };

  const closeSearchPopup = () => {
    setIsSearchPopupOpen(false);
  };

  // Pencere odaklanma yönetimi
  const handleWindowFocus = (id) => {
    setActiveWindow(id);
    // Pencereleri z-index ile yönetin
  };

  // Bildirim sistemi
  const addNotification = (message, type = 'info') => {
    const id = Date.now();
    setNotifications(prev => [...prev, { id, message, type }]);
    setTimeout(() => removeNotification(id), 5000);
  };

  return (
    <div className="ana-sayfa" ref={containerRef} style={{ minHeight: containerHeight, height: 'auto' }}>
      <div className="left-side-parent">
        <LeftSideMenu isActive={activemenu} onToggle={() => setactivemenu(!activemenu)} />
        <div className="frame-container">
          <TopMenu 
            onMenuToggle={() => setactivemenu(!activemenu)}
            onSearchClick={handleSearchClick}
          />
          <div className="section-parent">
            <div className="section">
              <div className="group-parent">
              <div className="text-parent">
                  <div className="text1" style={{ marginTop: '-25px' , fontSize: '12px' , width: '650px' }}>Web panelimiz test aşamasındadır panelin eksikliklerini veya olmasını istediğiniz şeyleri <br /> hunter@investcode.company e mail atarak gelişmesine katkı sağlayabilirsiniz.</div>
                </div>
              </div>
            </div>
            <div className="frame-parent1" style={styles.featuresContainer}>
              {features.map((feature, index) => (
                <button 
                  key={index} 
                  onClick={() => addWindow(feature)} 
                  className="button"
                  style={styles.featureButton} // Yeni stil eklendi
                >
                  <div className="text11">{feature.title}</div>
                </button>
              ))}
            </div>
            <div className="frame-child" />
            <div className="listeli" style={{ minHeight: '100%', position: 'relative' }}>
              {derinlikWindows.map(window => (
                <DraggableWindow
                  key={window.id}
                  id={window.id}
                  title="Derinlik"
                  onClose={() => closeWindow(window.id, 'derinlik')}
                  position={window.position}
                  onDragStop={(id, position) => updateWindowPosition(id, position, 'derinlik')}
                  onResize={(id, size) => updateWindowSize(id, size, 'derinlik')}
                  isDerinlikWindow={true}
                  initialSize={window.size}
                >
                  <Derinlikwindow 
                    hisseKodu={window.hisse} 
                    onHisseChange={(hisse) => updateHisse(window.id, hisse, 'derinlik')}
                  />
                </DraggableWindow>
              ))}
              {takasWindows.map(window => (
                <DraggableWindow
                  key={window.id}
                  id={window.id}
                  title="Takas Analizi"
                  onClose={() => closeWindow(window.id, 'takas')}
                  position={window.position}
                  onDragStop={(id, position) => updateWindowPosition(id, position, 'takas')}
                  onResize={(id, size) => updateWindowSize(id, size, 'takas')}
                  isTakasWindow={true}
                  initialSize={window.size}
                >
                  <TakasWindow 
                    hisseKodu={window.hisse} 
                    onHisseChange={(hisse) => updateHisse(window.id, hisse, 'takas')}
                  />
                </DraggableWindow>
              ))}
              {temettuWindows.map(window => (
                <DraggableWindow
                  key={window.id}
                  id={window.id}
                  title="Temettü"
                  onClose={() => closeWindow(window.id, 'temettu')}
                  position={window.position}
                  onDragStop={(id, position) => updateWindowPosition(id, position, 'temettu')}
                  onResize={(id, size) => updateWindowSize(id, size, 'temettu')}
                  isTemettuWindow={true}
                  initialSize={window.size}
                >
                  <TemettuWindow 
                    hisseKodu={window.hisse} 
                    onHisseChange={(hisse) => updateHisse(window.id, hisse, 'temettu')}
                  />
                </DraggableWindow>
              ))}
              {fondagilimiWindows.map(window => (
                <DraggableWindow
                  key={window.id}
                  id={window.id}
                  title="Fon Dağılımı"
                  onClose={() => closeWindow(window.id, 'fondagilimi')}
                  position={window.position}
                  onDragStop={(id, position) => updateWindowPosition(id, position, 'fondagilimi')}
                  onResize={(id, size) => updateWindowSize(id, size, 'fondagilimi')}
                  isFondagilimiWindow={true}
                  initialSize={window.size}
                >
                  <FondagilimiWindow 
                    hisseKodu={window.hisse} 
                    onHisseChange={(hisse) => updateHisse(window.id, hisse, 'fondagilimi')}
                  />
                </DraggableWindow>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isSearchPopupOpen && (
        <div className="search-popup-overlay">
          <div className="search-popup">
            <button className="close-button" onClick={closeSearchPopup}>X</button>
            <div className="select-container">
              <SelectComponent />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
    
  window: {
   
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    position: 'absolute',
    padding: '10px',
    overflow: 'auto',
  },
  selectWindow: {
    position: 'absolute',
    overflow: 'auto',
    backgroundColor: 'var(--color-gray-100)',
    borderRadius: '8px',
  },
  handle: {
    width: '100%',
    height: '100%',
    cursor: 'move',
    padding: '10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
    color: 'var(--white)',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    color: 'var(--white)',
    cursor: 'pointer',
    fontSize: '16px',
  },
  content: {
    padding: '10px',
    color: 'var(--white)',
    height: 'calc(100% - 30px)',
    overflow: 'auto',
  },
  derinlikWindow: {
    position: 'absolute',
    backgroundColor: 'var(--color-gray-800)',
    borderRadius: '35px',
    overflow: 'hidden',
  },
  takasWindow: {
    position: 'absolute',
    backgroundColor: 'var(--color-gray-800)',
    borderRadius: '35px',
    overflow: 'hidden',
  },
  featuresContainer: {
    position: 'absolute',
    top: '70px',
    right: '10px',
    display: 'flex',
    flexDirection: 'row', // 'column' yerine 'row' kullanıyoruz
    alignItems: 'center', // 'flex-end' yerine 'center' kullanıyoruz
    zIndex: 1000,
  },
  featureButton: {
    marginLeft: '10px', // Butonlar arasında boşluk bırakmak için
  },
  temettuWindow: {
    position: 'absolute',
    backgroundColor: 'var(--color-gray-800)',
    borderRadius: '35px',
    overflow: 'hidden',
  },
  temettuContent: {
    padding: '10px',
    color: 'var(--white)',
    height: 'calc(100% - 30px)',
    overflow: 'hidden', // 'auto' yerine 'hidden' kullanıyoruz
  },
  fondagilimiWindow: {
    position: 'absolute',
    backgroundColor: 'var(--color-gray-800)',
    borderRadius: '35px',
    overflow: 'hidden',
  },
};

export default App;